@use "sass:color";@import "@skryv/core-ng1/theme/includes/index";
rentesubsidie {
  .dossier-info-field { // based on skr-dossier-tile-info
    margin-bottom: $base-spacing;
    font-weight: $font-weight-light;

    dt {
      text-transform: capitalize;
      font-weight: $font-weight-normal;
    }

    dd {
      font-weight: $font-weight-light;

      &.not-applicable {
        color: #e9e9e9;
      }
    }
  }
}

skr-dossier-tile-active-task {
  .info-block {
    margin-top: -$base-spacing;
  }
}

.no-active-task {
  margin-left: -$base-spacing;
  margin-right: -$base-spacing;
  margin-top: -$base-spacing;
  margin-bottom: $base-spacing;
  padding: 2* $base-spacing $base-spacing;

  border: 1px solid $toolbar-background-color;
  color: $toolbar-background-color;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .no-active-task-text {
    font-size: 1.5*$base-font-size;
  }
  .no-active-task-icon {
    margin-right: $base-spacing;
  }
}
.not-last-task {
  .task {
    border-bottom: 1px solid $secondary-menu-background;
  }
}

.process-steps-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  cursor: pointer;
}

.process-steps-footer {
  margin: auto;
  cursor: pointer;
}

.process-steps-button {
  color: $white;
  text-align: center;
  font-size: 10px;
  line-height: 20px;

  background-color: $toolbar-background-color;
  border-radius: 50%;

  height: 20px;
  width: 20px;
}

skr-dossier-tile-process-steps {
  .process-steps {
    margin-top: 0;
    padding-right: $base-spacing;
  }
}

.skr-icon-deadline {
  padding-right: 5px;
}